<template>
	<v-sheet class="main-detail">
		<div class="">
			<v-layout class="section-main-content">
				<div
					style="height: calc(100vh - 69px) !important; width: 350px !important"
					class="listing-sidebar border-right"
				>
					<div
						:style="{ height: `calc(100vh - ${showLoadMoreBtn ? '120px' : '80px'}) !important` }"
						class="side-listing listing-sidebar-list"
					>
						<div class="d-flex justify-space-between align-center">
							<v-progress-circular
								v-if="pageLoading"
								indeterminate
								color="blue darken-4"
							></v-progress-circular>

							<v-menu v-else offset-y right bottom content-class="elevation-4 rounded-lg">
								<template v-slot:activator="{ on, attrs }">
									<p class="sub-heading m-0" v-on="on" v-bind="attrs">
										{{ currentStatus.text }}
										<v-icon>{{
											attrs && attrs["aria-expanded"] == "true" ? "mdi-menu-up" : "mdi-menu-down"
										}}</v-icon>
									</p>
								</template>
								<v-list>
									<v-list-item
										v-for="(status, index) in listingStatus"
										:key="index"
										@click="(page = 1), statusFilter(status.value, status.text)"
										class="px-1"
									>
										<v-list-item-title class="d-flex align-items-center">
											<v-row>
												<v-col md="9" class="pl-3 pr-0">
													<div class="fw-500">{{ status.text }}</div>
												</v-col>
												<v-col md="2" class="status-chip pa-0">
													<!-- <div
														class="white--text justify-center d-flex align-center"
														style="border-radius: 50%; height: 25px; width: auto; min-width: min-content;"
														:class="status.status_color"
													>
														{{ status.status_count }}
													</div> -->

													<v-chip
														class="text-center py-0 status-chip"
														dark
														small
														style="padding: 0 0"
														:color="status.status_color"
													>
														{{ status.status_count }}
													</v-chip>
												</v-col>
											</v-row>
										</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
							<div class="mr-2 my-1 fw-600">
								Current Records
								<v-chip color="primary" outlined class="ml-1">{{ statusData.length }}</v-chip>
							</div>
						</div>
						<div class="listing-innerlist">
							<!-- v-if="!pageLoading" -->
							<!-- <template> -->
							<template v-if="statusData.length">
								<div
									class="px-1"
									:style="{
										height: `calc(100vh - ${showLoadMoreBtn ? '153px' : '113px'}) !important`,
										overflowY: 'auto',
									}"
								>
									<div
										class="list-items rounded-lg"
										v-for="(data, index) in statusData"
										:key="index"
										:class="data.id == customerId ? 'active' : ''"
										@click="tabDetails(data.id)"
									>
										<template v-if="data.customer_invoices">
											<v-tooltip color="red" top content-class="red custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<div class="flagtool red" v-on="on" v-bind="attrs"></div>
												</template>
												<span>Invoice Pending</span>
											</v-tooltip>
										</template>

										<div class="ellipsis mb-1 d-flex justify-space-between">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<div class="text-truncate" style="max-width: 230px" v-on="on" v-bind="attrs">
														<v-icon class="mdi-13px">fas fa-building</v-icon>
														<span class="fw-600" v-if="data && data.company_name">{{ data.company_name }}</span>
														<span v-else>no company name</span>
													</div>
												</template>
												<span>Company Name</span>
											</v-tooltip>
											<v-chip
												label
												dark
												x-small
												class="px-1"
												:color="data.status == 'Active' ? 'green' : 'red'"
											>
												{{ data.status }}
											</v-chip>
										</div>

										<div class="ellipsis pr-6 mb-1">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<span v-on="on" v-bind="attrs">
														<v-icon class="mdi-13px">fas fa-user</v-icon>
														<span v-if="data && data.defaultContact && data.defaultContact.name">{{
															data.defaultContact.name
														}}</span>
													</span>
												</template>
												<span>Person Incharge</span>
											</v-tooltip>
										</div>

										<div class="ellipsis pr-6 mb-1">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<span v-on="on" v-bind="attrs">
														<v-icon class="mdi-14px mr-1" style="font-size: 20px">mdi-cog</v-icon>
													</span>
												</template>
												<span>Services</span>
											</v-tooltip>
											<template v-if="data.customer_service_type">
												<span
													v-for="(row, index) in data.customer_service_type"
													:key="index"
													class="cursor-initial"
												>
													<template v-if="row == 'seo'">
														<Chip text="SEO" color="blue" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="row == 'hosting'">
														<Chip text="H" color="blue-grey" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="row == 'ssl'">
														<Chip text="SSL" color="green" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="row == 'web'">
														<Chip text="W" color="orange" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="row == 'domain'">
														<Chip text="D" color="red" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="row == 'maintenance'">
														<Chip text="M" color="cyan" outlined text-color="" class="mr-1"> </Chip>
													</template>
													<template v-if="row == 'software'">
														<Chip text="S" color="blue darken-4" outlined text-color="" class="mr-1"> </Chip>
													</template>
												</span>
											</template>
											<em v-else class="text-muted"> no services </em>
										</div>

										<div class="ellipsis pr-6 mb-1">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<span v-on="on" v-bind="attrs">
														<v-icon class="mdi-14px" style="font-size: 20px">mdi-currency-usd</v-icon>
														<v-chip label color="green white--text" x-small v-if="data && data.invoice_total">
															<span class="fw-500" v-if="data && data.invoice_total">{{
																formatMoney(data.invoice_total)
															}}</span>
														</v-chip>
														<em v-else class="text-muted"> no cost </em>
													</span>
												</template>
												<span>Cost</span>
											</v-tooltip>
										</div>

										<div class="d-flex pr-10 mb-1">
											<div class="ellipsis fw-500 mr-2 w-50">
												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<span v-bind="attrs" v-on="on">
															<v-icon class="mdi-16px" color="blue darken-4">mdi-phone</v-icon>
															<span
																class="blue--text text--darken-4 cursor-pointer"
																v-if="data && data.defaultContact && data.defaultContact.phone_number"
																v-on:click.prevent.stop="copyNumber(data.defaultContact.phone_number, 'phone')"
																>{{ data.defaultContact.phone_number }}</span
															>
															<span v-else> no phone </span>
														</span>
													</template>
													<span>Phone Number</span>
												</v-tooltip>
											</div>
											<v-spacer></v-spacer>
											<div class="fw-500 ellipsis w-50">
												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<span v-bind="attrs" v-on="on">
															<v-icon class="mdi-16px" color="blue darken-4">mdi-email-outline</v-icon>
															<span
																class="blue--text text--darken-4 cursor-pointer"
																v-if="data && data.defaultContact && data.defaultContact.email_address"
																v-on:click.prevent.stop="copyNumber(data.defaultContact.email_address)"
																>{{ data.defaultContact.email_address }}</span
															>
															<span v-else> no email </span>
														</span>
													</template>
													<span>Email</span>
												</v-tooltip>
											</div>
										</div>

										<div class="pr-10">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<span v-on="on" v-bind="attrs">
														<v-icon class="mdi-16px pr-1">fas fa-map-marker-alt</v-icon>
														<template
															v-if="
																(data && data.address_line_1) ||
																data.address_line_2 ||
																data.postal_code ||
																data.country
															"
														>
															<span style="text-transform: capitalize">
																<template v-if="data && data.address_line_1"> {{ data.address_line_1 }}, </template>
																<template v-if="data && data.address_line_2"> {{ data.address_line_2 }}, </template>
																<template v-if="data && data.unit_number"> {{ data.unit_number }}, </template>
																<template v-if="data && data.country">
																	{{ data.country }}
																</template>
																<template v-if="data && data.postal_code"> {{ data.postal_code }}, </template>
															</span>
														</template>
														<template v-else> <em>no address</em> </template>
													</span>
												</template>
												<span>Address</span>
											</v-tooltip>
										</div>

										<!-- <v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox> -->
									</div>
								</div>
							</template>
							<template v-else>
								<div v-if="pageLoading" class="text-center mt-10 h4">Loading...</div>
								<p v-else class="text-center my-10 text-muted fw-500 h6">No customers</p>
							</template>
							<!-- </template> -->
							<!-- <template v-else>
								<div class="text-center mt-10 h4">Loading...</div>
							</template> -->
						</div>
					</div>
					<!-- Load more customers in left sidebar -->
					<div class="text-center my-2" v-if="showLoadMoreBtn">
						<v-btn
							color="primary"
							@click="
								() => {
									if (pageLoading) {
										return false;
									} else {
										loadMoreCustomers();
									}
								}
							"
						>
							<template v-if="pageLoading">Loading...</template>
							<template v-else> <v-icon left>mdi-reload</v-icon>Load more </template>
						</v-btn>
					</div>
					<!-- customer details -->

					<!-- customer details end -->
				</div>
				<div class="details-body">
					<div class="p-sticky">
						<v-layout
							class="pt-2 pb-0 light-blue-bg flex-wrap"
							style="position: sticky; top: 0; z-index: 9"
						>
							<v-flex md="12" class="d-flex px-4">
								<v-chip
									class="mr-1 text-capitalize"
									color="cyan white--text"
									x-small
									label
									v-if="this.customerDetail && this.customerDetail.barcode"
									>{{ this.customerDetail.barcode }}
								</v-chip>
								<v-spacer></v-spacer>
								<template v-if="dataTab == 'contract'">
									<v-btn
										v-if="getPermission('contract:create')"
										v-on:click="create_contract"
										color="blue darken-4 text-white mr-2"
										class="ml-2"
										depressed
										tile
										>Create Contract</v-btn
									>
								</template>
								<template v-if="dataTab == 'project' && customer_contract.length > 0">
									<v-btn
										v-if="getPermission('project:create')"
										v-on:click="covertProject"
										color="blue darken-4 text-white mr-1"
										class="ml-2"
										depressed
										tile
										>Create Project</v-btn
									>
								</template>
								<div v-if="dataTab == 'meeting'">
									<v-btn
										v-if="getPermission('meeting:create')"
										depressed
										tile
										class="blue darken-4 mr-2 my-auto"
										color="white--text "
										@click="open_meeting_drawer('Create')"
									>
										<v-icon class="mr-1">mdi-plus</v-icon>
										Create Meeting
									</v-btn>
								</div>
								<div v-if="dataTab == 'reminder'">
									<v-btn
										v-if="getPermission('reminder:create')"
										depressed
										tile
										class="blue darken-4 mr-2 my-auto"
										color="white--text "
										@click="open_reminder_drawer()"
									>
										<v-icon class="mr-1">mdi-plus</v-icon>
										Create Reminder
									</v-btn>
								</div>
								<v-btn
									v-if="getPermission('customer:update')"
									class="white--text mr-2"
									depressed
									color="blue darken-4"
									tile
									v-on:click="routeToUpdate(customerId)"
								>
									Edit
								</v-btn>
								<v-btn depressed tile class="my-auto" @click="goBackData()">
									<v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
									back
								</v-btn>
							</v-flex>
							<v-flex md12 class="px-4">
								<v-tooltip right content-class="custom-right-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<span v-on="on" v-bind="attrs">
											<i class="fa-solid fa-clock-rotate-left"></i>
											<span
												class="my-auto font-level-3-bold text-capitalize custom-title ml-0"
												v-if="customerDetail && customerDetail.company_name"
											>
												{{ customerDetail.company_name }}
											</span>

											<em v-else class="text-muted"> no company name </em>
										</span>
									</template>
									<span>Company Name</span>
								</v-tooltip>
							</v-flex>

							<v-spacer></v-spacer>
							<v-flex md12 class="d-flex flex-wrap px-4">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Sales Persons : </span>
										<span
											class="fw-500"
											v-if="salesPersonList && salesPersonList.attendies && salesPersonList.attendies.length"
										>
											<!-- v-for="(data, index) in salesPersonList.attendies"
										:key="index" -->
											<v-chip class="mb-1 mr-1" small>
												<v-avatar left small>
													<img
														v-if="getValue(salesPersonList, 'attendies.0.profile_img')"
														:src="getValue(salesPersonList, 'attendies.0.profile_img')"
													/>
													<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
												</v-avatar>
												<span
													v-if="salesPersonList.attendies[1] && salesPersonList.attendies[1].display_name"
													>{{ salesPersonList.attendies[1].display_name }}</span
												>
											</v-chip>

											<v-chip class="mb-1 mr-1" v-if="salesPersonList.attendies[2]" small>
												<!-- v-for="(data, index) in salesPersonList.attendies"
												:key="index" -->
												<v-avatar left small>
													<img
														v-if="salesPersonList.attendies[2].profile_img"
														:src="salesPersonList.attendies[2].profile_img"
													/>
													<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
												</v-avatar>
												<span
													v-if="salesPersonList.attendies[2] && salesPersonList.attendies[2].display_name"
													>{{ salesPersonList.attendies[2].display_name }}</span
												>
											</v-chip>

											<v-chip
												v-if="salesPersonList.attendies.length > 2"
												@click="(moreDatalist = true), (moreDataDialogTitle = 'Sales Person')"
												color="blue"
												class="mb-1 mr-1"
												small
											>
												<span class="white--text"> +{{ salesPersonList.attendies.length - 2 }} more </span>
											</v-chip>
										</span>
										<span v-else> no sales person </span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2 word-count">
										<span class="fw-600">Cost : </span>
										<v-chip
											label
											color="green white--text"
											x-small
											v-if="customerDetail && customerDetail.invoice_total && customerDetail.invoice_total"
										>
											<span
												class="fw-500"
												v-if="customerDetail && customerDetail.invoice_total && customerDetail.invoice_total"
											>
												{{ formatMoney(customerDetail.invoice_total) }}</span
											>
										</v-chip>
										<span class="" v-else>no cost</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2 word-count">
										<span class="fw-600">Source : </span>
										<span class="fw-500" v-if="customerDetail && customerDetail.source"
											><v-chip
												class="ml-2 text-capitalize"
												color="blue white--text"
												x-small
												label
												v-if="this.customerDetail && this.customerDetail.source"
												>{{ this.customerDetail.source.replace("-", " ") }}</v-chip
											>
										</span>
										<span v-else> no source </span>
									</div>
								</div>
							</v-flex>
							<v-flex md12 class="d-flex flex-wrap px-4">
								<div class="w-33">
									<div class="mt-2 d-flex">
										<span class="fw-600 text-no-wrap">Address : </span>
										<span class="fw-500 pl-2" v-if="customerDetail">
											<template v-if="customerDetail.address_line_1">
												{{ customerDetail.address_line_1 }},
												<br />
											</template>
											<template v-if="customerDetail.address_line_2">
												{{ customerDetail.address_line_2 }},
											</template>
											<template v-if="customerDetail.unit_number">
												{{ customerDetail.unit_number }},
												<br />
											</template>
											<template v-if="customerDetail.country"> {{ customerDetail.country }}, </template>
											<template v-if="customerDetail.postal_code">
												{{ customerDetail.postal_code }}
											</template>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Website : </span>
										<span
											class="cursor-pointer"
											v-if="customerDetail && customerDetail.website"
											v-on:click.prevent.stop="copyNumber(customerDetail.website)"
											>{{ customerDetail.website }}</span
										>
										<span v-else> no website </span>
									</div>
								</div>
							</v-flex>

							<v-flex md12 class="px-4 mt-2 grey lighten-4">
								<v-tabs
									v-model="dataTab"
									background-color="transparent"
									color="blue"
									class="custom-tab-transparent border-bottom w-100"
									active-class="blue darken-4 text-white"
									hide-slider
								>
									<v-tab href="#overview">
										<v-icon small left>mdi-information-outline</v-icon>Overview
									</v-tab>
									<v-tab href="#notes" v-if="false"
										><v-icon small left>mdi-note-plus-outline</v-icon>Notes</v-tab
									>
									<v-tab href="#meeting" v-on:click="getMeeting()">
										<v-icon small left>mdi-timeline-clock-outline</v-icon>Meeting</v-tab
									>
									<v-tab href="#reminder"> <v-icon small left>mdi-alarm-check</v-icon>Reminder</v-tab>
									<v-tab href="#contract" v-if="false">
										<v-icon small left>mdi-information-outline</v-icon>Contract
									</v-tab>
									<v-tab href="#project" v-if="false">
										<v-icon small left>mdi-information-outline</v-icon>Project
									</v-tab>
									<v-tab href="#invoice" v-if="false">
										<v-icon small left>mdi-information-outline</v-icon>Invoice
									</v-tab>
									<!-- <v-tab href="#remark"><v-icon small left>mdi-note-plus-outline</v-icon>Remarks</v-tab> -->

									<!-- <v-tab href="#seo"> <v-icon small left>mdi-poll</v-icon>SEO </v-tab>
									<v-tab href="#host"> <v-icon small left>mdi-poll</v-icon>HOST </v-tab>
									<v-tab href="#ssl"> <v-icon small left>mdi-poll</v-icon>SSL </v-tab>
									<v-tab href="#web"> <v-icon small left>mdi-poll</v-icon>WEB </v-tab>
									<v-tab href="#domain"> <v-icon small left>mdi-poll</v-icon>Domain </v-tab>
									<v-tab href="#maintenance"> <v-icon small left>mdi-poll</v-icon>Maintenance </v-tab> -->
									<v-tab href="#file"><v-icon small left>mdi-file-document-multiple</v-icon>Files</v-tab>
									<!-- <v-tab href="#ranking"><v-icon small left>mdi-information-outline</v-icon>KPI</v-tab> -->
									<v-tab href="#activity" v-on:click="taskActivity()"
										><v-icon small left>mdi-information-outline</v-icon>Activity</v-tab
									>
								</v-tabs>
							</v-flex>
						</v-layout>
						<div class="">
							<v-tabs-items v-model="dataTab" class="transparent">
								<v-tab-item value="overview">
									<div class="flaot-wrapper">
										<v-col cols="12" lg="6">
											<CompanyDetail :customer-detail="customerDetail"></CompanyDetail>
										</v-col>
										<v-col md="12" lg="6">
											<ContactPerson
												:contact-detail="contactDetails"
												delete-url="customer-contact"
												primary-url="contact-primary"
												:related-id="customerId"
												v-on:deleteSuccess="refreshpage"
												v-on:open_contact_drawer="openContactDrawer($event)"
											></ContactPerson>
										</v-col>
										<v-col md="12" lg="12">
											<ClientScore :client-score="customerDetail"></ClientScore>
										</v-col>
									</div>
								</v-tab-item>

								<v-tab-item value="notes">
									<AllNotesTab
										v-if="dataTab == 'notes'"
										:relatedId="customerId"
										:relatedType="'customer-notes'"
										create-url="customer-note"
										get-url="customer-note"
									>
									</AllNotesTab>
								</v-tab-item>
								<v-tab-item value="meeting">
									<v-col md="12" lg="12">
										<v-layout class="pa-2 border-light-grey min-height-40px">
											<v-flex class="font-level-3-bold my-auto">
												<v-icon color="blue darken-4" left>mdi-timeline-clock-outline</v-icon>
												Meeting
												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															v-if="getPermission('meeting:create')"
															v-on="on"
															v-bind="attrs"
															v-on:click="open_meeting_drawer('Create')"
															color="blue darken-4 text-white"
															class="ml-2 rounded-circle"
															depressed
															icon
															rounded
															outlined
															tile
															small
															min-width="24"
															width="24"
															height="24"
														>
															<v-icon small>mdi-plus</v-icon>
														</v-btn>
													</template>
													<span>Create Meeting</span>
												</v-tooltip>
											</v-flex>
										</v-layout>
										<div v-if="meetingData && meetingData.length">
											<!-- <div class="py-3">
												<div class="fs-16 fw-500 primary--text"></div>
											</div> -->
											<div v-for="(meetings, index) in meetingData" :key="index">
												<MeetingTab
													:open_details="false"
													v-on:editMeeting="editMeeting($event)"
													v-on:editTitle="open_meeting_drawer($event)"
													v-on:deleteMeeting="getMeeting"
													:index-meeting="index"
													:meeting="meetings"
													v-on:meetingSuccess="refreshMeeting"
												></MeetingTab>
											</div>
										</div>
										<template v-else>
											<p class="m-0 row-not-found text-center">
												<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
												Uhh... There are no meeting at the moment.
											</p>
										</template>
									</v-col>
								</v-tab-item>
								<v-tab-item value="reminder">
									<v-layout class="px-4 py-2">
										<v-tabs
											background-color="transparent"
											color="blue"
											class="custom-tab-transparent w-100"
											active-class="blue darken-4 text-white"
											hide-slider
											v-model="reminderTabs"
										>
											<v-tab href="#overdue" v-on:click="getReminder(1)">
												<v-icon small left>mdi-alarm-check</v-icon>Overdue ({{
													reminderCounting && reminderCounting.overdue_reminders_count
														? reminderCounting.overdue_reminders_count
														: 0
												}})</v-tab
											>
											<v-tab href="#upcoming" v-on:click="getReminder(2)">
												<v-icon small left>mdi-alarm-check</v-icon>upcoming ({{
													reminderCounting && reminderCounting.upcoming_reminders_count
														? reminderCounting.upcoming_reminders_count
														: 0
												}})</v-tab
											>
											<v-tab href="#complete" v-on:click="getReminder(3)">
												<v-icon small left>mdi-alarm-check</v-icon>Completed ({{
													reminderCounting && reminderCounting.completed_reminders_count
														? reminderCounting.completed_reminders_count
														: 0
												}})</v-tab
											>
										</v-tabs>
									</v-layout>
									<div class="tab-layout-content px-4">
										<v-tabs-items v-model="reminderTabs">
											<v-tab-item value="overdue">
												<div v-if="reminderData && reminderData.length" class="mt-1 mx-2">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTab
															v-on:editReminder="editReminderForm($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccess="refreshReminder"
														>
														</ReminderTab>
													</div>
												</div>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no reminder at the moment.
													</p>
												</template>
											</v-tab-item>
											<v-tab-item value="upcoming">
												<div v-if="reminderData && reminderData.length" class="mt-1 mx-2">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTab
															v-on:editReminder="editReminderForm($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccess="refreshReminder"
														>
														</ReminderTab>
													</div>
												</div>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no reminder at the moment.
													</p>
												</template>
											</v-tab-item>
											<v-tab-item value="complete">
												<div v-if="reminderData && reminderData.length" class="mt-1 mx-2">
													<div v-for="(reminder, index) in reminderData" :key="index">
														<ReminderTab
															v-on:editReminder="editReminderForm($event)"
															:open_details="!index ? true : false"
															:remind="reminder"
															:index-reminder="index"
															v-on:reminderSuccess="refreshReminder"
														>
														</ReminderTab>
													</div>
												</div>
												<template v-else>
													<p class="m-0 row-not-found text-center">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no reminder at the moment.
													</p>
												</template>
											</v-tab-item>
										</v-tabs-items>
									</div>
								</v-tab-item>
								<v-tab-item value="contract">
									<v-col md="12" lg="12">
										<!-- <ContactListing
											v-if="dataTab == 'contract' && false"
											:related-id="customerId"
											internaldata
											:updated-data="autoUpdate"
										></ContactListing> -->
										<ContactInternalListing
											v-if="dataTab == 'contract'"
											:customer-id="customerId"
											internaldata
											:updated-data="autoUpdate"
										></ContactInternalListing>
									</v-col>
								</v-tab-item>
								<v-tab-item value="project">
									<v-col md="12" lg="12">
										<!-- <ProjectListing
											v-if="dataTab == 'project' $$"
											:related-id="customerId"
											internaldata
											:updated-data="autoUpdate"
										></ProjectListing> -->
										<ProjectInternalListing
											v-if="dataTab == 'project'"
											:customer-id="customerId"
											internaldata
											:updated-data="autoUpdate"
										></ProjectInternalListing>
									</v-col>
								</v-tab-item>
								<v-tab-item value="invoice">
									<v-col md="12" lg="12">
										<!-- <RecurringInvoiceTab
											v-if="dataTab == 'invoice'"
											:related-id="customerId"
											internaldata
											:updated-data="autoUpdate"
										></RecurringInvoiceTab> -->
										<IntenalInvoiceTab
											v-if="dataTab == 'invoice'"
											:customer-id="customerId"
											:updated-data="autoUpdate"
										></IntenalInvoiceTab>
									</v-col>
								</v-tab-item>

								<!-- <v-tab-item value="remark">
									<AllNotesTab
									    v-if="dataTab == 'remark'"
										:relatedId="customerId"
										:relatedType="'customer-remark-notes'"
										create-url="customer-remark"
										get-url="customer-remark"
										:default-remakr="customerDetail.remark"
										:default-date="customerDetail.added_at"
										v-on:refress="getSingleCustomer"
									>
									</AllNotesTab>
								</v-tab-item> -->
								<v-tab-item value="ranking">
									<div class="white">
										<div v-if="dataTab == 'ranking'">
											<v-layout class="p-4 border-bottom-light-grey min-height-57px">
												<v-flex class="font-level-3-bold my-auto">
													<span class="detail-svg-icon mr-2">
														<!--begin::Svg Icon-->
														<inline-svg :src="$assetURL('media/custom-svg/files-color.svg')" />
														<!--end::Svg Icon-->
													</span>
													Files
												</v-flex>
												<v-flex class="text-right">
													<v-btn
														v-on:click="uploadDialog"
														color="blue darken-4 text-white"
														class="ml-2"
														depressed
														tile
														><v-icon small left>mdi-playlist-plus</v-icon>Attach File</v-btn
													>
												</v-flex>
											</v-layout>
											<div class="overflow-y" style="max-height: calc(100vh - 345px)">
												<table width="100%" class="detail-table table-head-sticky">
													<thead>
														<tr>
															<th class="p-2 light-blue-bg" width="50"></th>
															<th class="p-2 light-blue-bg">Name</th>
															<th class="p-2 light-blue-bg">User Name</th>
															<th class="p-2 light-blue-bg">Date</th>
															<th class="p-2 light-blue-bg">File Size</th>
															<th class="p-2 light-blue-bg"></th>
														</tr>
													</thead>
													<tbody v-if="dbFiles.length">
														<tr v-for="(row, index) in dbFiles" :key="index">
															<td class="p-2 border-top-light-grey" width="50">
																<template
																	v-if="
																		getFileExtension(row.url) == 'jpg' ||
																		getFileExtension(row.url) == 'jpeg' ||
																		getFileExtension(row.url) == 'png' ||
																		getFileExtension(row.url) == 'jfif'
																	"
																>
																	<ImageTemplate style="max-width: 50px; width: 50px" :src="row.url"></ImageTemplate>
																</template>
																<template v-if="getFileExtension(row.url) == 'pdf'">
																	<inline-svg :src="$assetURL('media/mime/pdf.svg')" />
																</template>
																<template
																	v-if="getFileExtension(row.url) == 'docx' || getFileExtension(row.url) == 'doc'"
																>
																	<inline-svg :src="$assetURL('media/mime/doc.svg')" />
																</template>
																<template
																	v-if="getFileExtension(row.url) == 'xls' || getFileExtension(row.url) == 'xlsx'"
																>
																	<inline-svg :src="$assetURL('media/mime/xls.svg')" />
																</template>
															</td>

															<td class="p-2 border-top-light-grey">
																<p class="m-0 blue--text font-level-1">{{ row.name }}</p>
																<!-- <span class="text-muted font-small">{{ formatDate(row.added_at) }} </span> -->
																<!-- <v-chip class="mb-1 mr-1" small
																	><v-avatar left small>
																		<img v-if="row.profile_img" :src="row.profile_img" />
																		<img
																			v-else
																			src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																		/>
																	</v-avatar>
																	<span v-if="row && row.display_name">{{ row.display_name }}</span>
																</v-chip> -->
															</td>
															<td class="p-2 border-top-light-grey">
																<v-chip class="mb-1 mr-1" small
																	><v-avatar left small>
																		<img v-if="row.profile_img" :src="row.profile_img" />
																		<img
																			v-else
																			src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
																		/>
																	</v-avatar>
																	<span v-if="row && row.display_name">{{ row.display_name }}</span>
																</v-chip>
															</td>
															<td class="p-2 border-top-light-grey">
																{{ formatDate(row.added_at) }}
															</td>
															<!-- <td class="p-2 border-top-light-grey font-level-1">
																<v-layout>
																	<v-flex md11> </v-flex>
																	<v-flex md1> {{ getFileSize(row.size) }} KB </v-flex>
																</v-layout>
															</td> -->
															<td class="p-2 border-top-light-grey font-level-1">
																<v-layout>
																	<!-- <v-flex md11> </v-flex> -->
																	<v-flex> {{ getFileSize(row.size) }} KB </v-flex>
																</v-layout>
															</td>
															<td class="p-2 border-top-light-grey font-level-1">
																<v-btn
																	v-on:click="doAction(row, 'download')"
																	small
																	icon
																	depressed
																	color="blue darken-4"
																	class="mr-2"
																	><v-icon large>mdi-download</v-icon></v-btn
																>
																<v-btn
																	small
																	v-on:click="doAction(row, 'delete')"
																	icon
																	depressed
																	color="red lighten-1"
																	><v-icon large>mdi-delete</v-icon></v-btn
																>
															</td>
														</tr>
													</tbody>
													<tfoot v-else>
														<tr>
															<td colspan="4">
																<p class="m-0 row-not-found text-center">
																	<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
																	Uhh... There are no file at the moment.
																</p>
															</td>
														</tr>
													</tfoot>
												</table>
											</div>
											<Dialog :dialog="deleteDialog" :dialog-width="600">
												<template v-slot:title> Delete File</template>
												<template v-slot:body>
													<v-row class="delete-dialog">
														<v-col md="2" class="py-0 text-right my-auto">
															<span class="svg-icon svg-icon-lg delete-confirm-icon">
																<!--begin::Svg Icon-->
																<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
																<!--end::Svg Icon-->
															</span>
														</v-col>
														<v-col md="10" class="py-0 my-auto">
															<p class="btx-p m-0">
																Deleting File is irreversible, Are you sure about deleting it?
															</p>
														</v-col>
													</v-row>
												</template>
												<template v-slot:action>
													<v-btn
														class="white--text"
														:loading="deleteLoading"
														:disabled="deleteLoading"
														depressed
														color="red lighten-1"
														tile
														v-on:click="deleteFile()"
													>
														Yes! Delete
													</v-btn>
													<v-btn depressed tile :disabled="deleteLoading" v-on:click="deleteDialog = false">
														No, Close
													</v-btn>
												</template>
											</Dialog>
										</div>
									</div>
								</v-tab-item>
								<v-tab-item value="file">
									<Files
										v-if="dataTab == 'file'"
										class="mx-4"
										type="customer"
										:url="'customer'"
										:type-id="customerId"
									>
									</Files>
								</v-tab-item>
								<v-tab-item value="activity">
									<div class="pa-2">
										<v-timeline dense>
											<v-timeline-item
												color="blue darken-4"
												small
												v-for="(logs, index) in logData"
												:key="index"
											>
												<ActivityTab :open_details="false" :log="logs" title="Customer"> </ActivityTab>
											</v-timeline-item>
										</v-timeline>
										<!-- <div v-for="(logs, index) in logData" :key="index">
											<ActivityTab :open_details="true" :log="logs" title="Customer"></ActivityTab>
										</div> -->
									</div>
								</v-tab-item>
							</v-tabs-items>
						</div>
					</div>
				</div>
			</v-layout>
			<div class="reminder-icon">
				<div class="call-btn-underlay" style="background-color: rgb(243, 165, 165)"></div>
				<v-icon @click="open_reminder_drawer()" style="font-size: 70px !important" color="red" small
					>mdi-alarm-check</v-icon
				>
			</div>
			<div class="chat-icon">
				<div class="call-btn-underlay" style="background-color: rgb(43, 149, 105)"></div>
				<v-icon
					style="font-size: 70px !important"
					color="green"
					small
					v-on:click="CustomerDialog = true"
					>mdi-chat
				</v-icon>
			</div>
		</div>
		<Dialog :dialog="open_upload_dialog">
			<template v-slot:title> Upload KPI </template>
			<template v-slot:body>
				<template v-for="(data, index) in fileList">
					<v-row class="mb-3 px-4" :key="index">
						<v-col lg="10" cols="10" class="my-auto py-0 d-flex align-items-center">
							<v-text-field
								hide-details
								outlined
								v-model="data.name"
								:prepend-inner-icon="data.icon"
								:suffix="data.extension"
							>
							</v-text-field>
							<v-btn
								tile
								color="transparent"
								depressed
								class="border"
								style="height: 30px !important; border: 1px solid #00000061 !important"
								v-on:click="selectFile(index)"
							>
								<v-icon>mdi-attachment</v-icon>
							</v-btn>
							<span class="fw-600 ml-2 primary--text d-block" style="min-width: 100px">{{
								data.size
							}}</span>
						</v-col>
						<v-col lg="2" cols="2" class="my-auto py-0">
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<span v-on="on" v-bind="attrs">
										<v-btn tile icon depressed color="red white--text" v-on:click="remove_file(index)">
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</span>
								</template>
								<span>Remove File</span>
							</v-tooltip>
						</v-col>
					</v-row>
				</template>
				<v-row v-if="fileList.length < 1" class="mb-3 px-4">
					<v-col
						v-if="fileList.length < 1"
						md="12"
						class="py-10 text-center grey--text pointer"
						style="border: 2px dashed #ccc"
						v-on:click="selectFile(-1)"
					>
						<h4 class="fw-400">Upload File Here...</h4>
					</v-col>
				</v-row>
				<v-row v-else class="mb-3 px-4">
					<v-col lg="6" class="my-auto py-0">
						<v-btn tile depressed color="blue darken-4 white--text" v-on:click="addMoreFile()">
							<v-icon>mdi-plus</v-icon>
							Add More
						</v-btn>
					</v-col>
				</v-row>
				<v-file-input ref="uploadFile" class="d-none" v-on:change="uploadFile($event)"></v-file-input>
			</template>
			<template v-slot:action>
				<v-btn color="blue darken-4 white--text" tile depressed class="mr-2"> Save </v-btn>
				<v-btn tile depressed v-on:click="closeDialog()"> Cancel </v-btn>
			</template>
		</Dialog>
		<template>
			<!-- <Contact
							is-dialog
							:customer-id="customerId"
							v-on:close="updateDialog = false"
							v-on:refresh="
								updateDialog = false;
								getLeadListing();
							"
						>
						</Contact> -->
			<Contact
				v-if="updateDialog"
				v-on:success="(updateDialog = false), getSingleCustomer()"
				v-on:close="updateDialog = false"
				:update-dialog="updateDialog"
				:customer-id="customerId"
				:internal-data="true"
				v-on:refress="(updateDialog = false), autoRefress()"
			>
			</Contact>
			<CreateProject
				v-if="projectDialog"
				v-on:success="(projectDialog = false), getSingleCustomer()"
				v-on:close="projectDialog = false"
				v-on:refress="(projectDialog = false), autoRefress()"
				:project-dialog="projectDialog"
				:customer-id="customerId"
			>
			</CreateProject>
		</template>
		<ContactUpdate
			v-if="openContact"
			:drawer="openContact"
			v-on:update_contact="updateContact"
			:contact-data="contactData"
			update-url="update-contact"
			:related-id="customerId"
			v-on:close="openContact = false"
		></ContactUpdate>
		<CreateInvoice
			v-if="CreateInvoiceDialog"
			v-on:success="(CreateInvoiceDialog = false), autoRefress()"
			v-on:close="CreateInvoiceDialog = false"
			v-on:refressdata="tabDetails()"
			:create-invoice-dialog="CreateInvoiceDialog"
			:related-id="customerId"
		>
		</CreateInvoice>
		<CreateMeeting
			v-if="open_dialog.meeting"
			:drawer="open_dialog.meeting"
			:title-status="meeting_title"
			:get-meeting-data="editMeetingData"
			:attendies="attendieList"
			v-on:updateMeetingSuccess="refreshMeeting"
			v-on:close="open_dialog.meeting = false"
			related="customer"
			:related-id="customerId"
		></CreateMeeting>
		<FullDialog v-if="CustomerDialog" :dialog="CustomerDialog" content-class="testdata">
			<template v-slot:title
				>Customer {{ customerDetail.barcode }} Notes
				<v-spacer></v-spacer>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialogRemark">
					Close
				</v-btn>
			</template>
			<template v-slot:body>
				<AllNotesTab
					v-if="CustomerDialog"
					:relatedId="customerId"
					:relatedType="'customer-notes'"
					create-url="customer-note"
					get-url="customer-note"
					:siggest-list="salesPersonList.attendies"
				>
				</AllNotesTab>
			</template>
		</FullDialog>

		<MoreData
			v-if="moreDatalist"
			:more-data-dialog="moreDatalist"
			:dialog-width="600"
			:dialog-title="moreDataDialogTitle"
			:more-data="salesPersonList.attendies"
			@close="moreDatalist = false"
			:barcode="customerDetail.barcode"
		></MoreData>

		<CreateOrUpdateReminderV2
			v-if="open_dialog.reminder"
			:drawer="open_dialog.reminder"
			:reminderId="edit_reminder_id"
			related="customer"
			:relatedId="customerId"
			v-on:success="refreshReminder"
			v-on:close="closeReminderDialog()"
		>
		</CreateOrUpdateReminderV2>
		<!-- <CreateReminder
			v-if="open_dialog.reminder"
			:drawer="open_dialog.reminder"
			:title-status="meeting_title"
			:reminder-single-data="editReminderData"
			:attendies="attendieList"
			v-on:updateRemindSuccess="refreshReminder"
			v-on:close="open_dialog.reminder = false"
			related="customer"
			:related-id="customerId"
		></CreateReminder> -->
		<add-dialog
			v-if="openUploadModel"
			:document-dialog="openUploadModel"
			v-on:close="openUploadModel = false"
			v-on:success="getKpi()"
			:related-id="customerId"
			:related-type="'customer_kpi'"
		></add-dialog>
	</v-sheet>
</template>
<style>
.theme--dark.v-overlay.v-overlay--active {
	z-index: 99 !important;
}

/* .layout.section-main-content {
	height: calc(97vh - 100px);
} */
</style>
<script>
import { toNumber, round, last } from "lodash";
/* import RecurringInvoiceTab from "@/view/module/components/RecurringInvoiceTab.vue"; */
/* import ContactListing from "@/view/module/contract/Contract.vue"; */
/* import ProjectListing from "@/view/module/project/Project.vue"; */
//import AllNotesTab from "@/view/pages/leads/components/NotesLeadTab";

import { ContractEventBus } from "@/core/lib/contract.lib";
import { SET_MESSAGE } from "@/core/services/store/common.module";
// import CreateReminder from "@/view/pages/leads/create/CreateReminder";
//import Financials from "@/view/module/contacts/customer/components/Financials";
//import Attachments from "@/view/module/contacts/customer/components/Attachments";
import { toSafeInteger, reduce, find } from "lodash";
import { GET, QUERY } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import objectPath from "object-path";
// import ReminderListingModule from "@/view/pages/leads/components/ReminderListingModule.vue"

export default {
	name: "customer-details",
	data() {
		return {
			currentStatus: { text: "All Customers", value: "all" },
			selected_date: "all",
			DataSearch: null,
			edit_reminder_id: 0,
			reminderTabs: "overdue",
			reminderCounting: {},
			contactID: null,
			contactData: {},
			listingStatus: [],
			statusData: [],
			logData: [],
			deleteDialog: false,
			CustomerDialog: false,
			updateDialog: false,
			projectDialog: false,
			openContact: false,
			CreateInvoiceDialog: false,
			deleteLoading: false,
			customerDetail: {},
			reminderData: null,
			editReminderData: {},
			customer_contract: [],
			customerList: [],
			meetingData: [],
			editMeetingData: null,
			attendieList: {},
			salesPersonList: {},
			contactDetails: [],
			servicesDetails: [],
			autoUpdate: false,
			attachmentDetails: [],
			customer_ssl: [],
			customer_web: [],
			customer_hosting: [],
			meeting_title: null,
			domain: [],
			maintenance: [],
			btxPlaceholder: [
				{ value: "all", text: "All" },
				{ value: "customer-notes", text: "Customer" },
				{ value: "meeting-notes", text: "Meeting" },
			],
			open_upload_dialog: false,
			/* 	dataTab: "overview", */
			openUploadModel: false,
			open_dialog: {
				notes: false,
				task: false,
				meeting: false,
				reminder: false,
				add_company: false,
				add_deal: false,
				add_attachment: false,
			},
			fileIndex: -1,
			tagItems: ["Software", "SEO"],
			filter_tag: "Software",
			userItems: ["Everyone"],
			filter_user: "Everyone",
			filter_customer: "",
			fileList: [],
			dbFiles: [],
			list1: [
				{
					analysis_code: "analysis-1413243141",
					date: "12/04/2022",
				},
				{
					analysis_code: "analysis-1413243141",
					date: "12/04/2022",
				},
				{
					analysis_code: "analysis-1413243141",
					date: "12/04/2022",
				},
			],
			moreDatalist: false,
			moreDataDialogTitle: null,
			page: 1,
			pageLoading: false,
		};
	},
	components: {
		// ReminderListingModule,
		/* MoreData,
		CompanyDetail,
		ContactPerson,
		Contact,
		CreateOrUpdateReminderV2,
		FullDialog,
		ReminderTab,
		ContactInternalListing,
		ProjectInternalListing,
		CreateProject,
		IntenalInvoiceTab,
		MeetingTab,
		CreateMeeting,
		Chip,
		ClientScore,
		Dialog,
		AddDialog,
		ContactUpdate,
		Files,
		AllNotesTab,
		CreateInvoice,
		ImageTemplate,
		ActivityTab, */
		// CreateReminder,
		/* ContactListing, */
		/* 	RecurringInvoiceTab, */
		/* 	ProjectListing, */
		//Financials,
		//	Attachments,

		ContactUpdate: () => import("@/view/module/contacts/customer/components/ContactUpdate.vue"),
		CompanyDetail: () => import("@/view/module/contacts/customer/components/CompanyDetail"),
		ContactPerson: () => import("@/view/module/contacts/customer/components/ContactPerson"),
		Contact: () => import("@/view/module/contract/Create-Contract"),
		CreateProject: () => import("@/view/module/project/CreateProject.vue"),
		IntenalInvoiceTab: () => import("@/view/module/components/Invoice-Internal-listing.vue"),
		ContactInternalListing: () => import("@/view/module/contract/Internal-Contract-listing"),
		ProjectInternalListing: () => import("@/view/module/project/Internal-Project-listing"),
		AllNotesTab: () => import("@/view/pages/leads/components/NotesTab3"),
		CreateInvoice: () => import("@/view/module/components/Create-invoice"),
		ActivityTab: () => import("@/view/pages/leads/components/ActivityTab"),
		MeetingTab: () => import("@/view/pages/leads/components/MeetingTab"),
		CreateMeeting: () => import("@/view/pages/leads/create/CreateMeeting"),
		Chip: () => import("@/view/components/Chip"),
		CreateOrUpdateReminderV2: () => import("@/view/pages/leads/create/CreateOrUpdateReminderV2"),
		ReminderTab: () => import("@/view/pages/leads/components/ReminderTab"),
		FullDialog: () => import("@/view/components/FullDialog"),
		ClientScore: () => import("@/view/module/contacts/customer/components/ClientScore"),
		Dialog: () => import("@/view/components/Dialog"),
		AddDialog: () => import("@/view/pages/leads/create/AddDialog"),
		Files: () => import("@/view/components/Files"),
		ImageTemplate: () => import("@/view/components/Image"),
		MoreData: () => import("@/view/components/MoreData.vue"),
	},
	methods: {
		// redirectReminder(){
		// 	this.dataTab = 'reminder'
		// },
		goBackData() {
			let filterQuery = this.$route.query;

			this.$router.push({
				name: "customer",
				query: {
					page: filterQuery.page,
					tab: null,
					t: new Date().getTime(),
					// viewType: this.viewType,
				},
			});
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		/* showLoadMoreBtn() {
			const allCustomers = parseInt(
				objectPath.get(
					this.listingStatus,
					`${this.currentStatus.value == "all" ? 0 : this.currentStatus.value}.status_count`
				)
			);
			if (allCustomers && allCustomers > 15 && allCustomers > this.statusData.length) {
				return true;
			} else {
				return false;
			}
		}, */
		loadMoreCustomers() {
			this.page++;
			this.statusFilter();
		},
		getCost(data) {
			let result = reduce(
				data,
				(acc, row) => {
					acc = toSafeInteger(acc) + toSafeInteger(row.paid_amount);
					return acc;
				},
				0
			);
			return result;
		},
		closeDialogRemark() {
			this.CustomerDialog = false;
		},
		refreshpage() {
			console.log("refreshpage");
			this.statusFilter();
			this.getSingleCustomer();
		},
		closeReminderDialog() {
			this.open_dialog.reminder = false;
			this.edit_reminder_id = 0;
		},
		open_reminder_drawer() {
			// this.editReminderData = {};
			// this.attendieList = {};
			// this.meeting_title = name;
			this.open_dialog.reminder = true;
			this.edit_reminder_id = 0;
		},
		editReminderForm(id) {
			this.open_dialog.reminder = true;
			this.edit_reminder_id = id;
			// this.$store
			// 	.dispatch(GET, { url: `single-reminder/${id}/customer` })
			// 	.then((data) => {
			// 		this.editReminderData = data.reminder;
			// 		this.attendieList = data.attendie[0];
			// 	})
			// 	.catch((error) => {
			// 		console.log({ error });
			// 	});
		},
		getReminder(type) {
			this.reminderTabs =
				type == 1 ? "overdue" : type == 2 ? "upcoming" : type == 2 ? "complete" : "overdue";
			this.$store
				.dispatch(QUERY, {
					url: `reminder`,
					data: { related_id: this.customerId, related_to: "customer", status: type ? type : 1 },
				})
				.then((data) => {
					if (type == 1) {
						this.reminderTabs = "overdue";
					} else if (type == 2) {
						this.reminderTabs = "upcoming";
					} else if (type == 3) {
						this.reminderTabs = "complete";
					}
					this.reminderData = data.rows;
					this.reminderCounting = data.countings ? data.countings : {};
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		// getReminder() {
		// 	this.$store
		// 		.dispatch(QUERY, {
		// 			url: `reminder`,
		// 			data: { related_id: this.customerId, related_to: "customer" },
		// 		})
		// 		.then((data) => {
		// 			this.reminderData = data.rows;
		// 			this.getSingleCustomer();
		// 		})
		// 		.catch((error) => {
		// 			console.log({ error });
		// 		});
		// },
		refreshReminder(row) {
			this.getReminder(row?.status);
		},
		onEsc() {
			this.resetSearch();
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.DataSearch = null;
				this.getNotesRemark();
			});
		},
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]);
		},
		covertProject() {
			const _this = this;
			_this.$router.push({
				name: "create-project",
				query: {
					customer_id: _this.customerId ? _this.customerId : 0,
				},
			});
		},

		getPriority(data) {
			let result = find(data, { status: 1 });
			return result;
		},

		create_contract() {
			this.updateDialog = true;
		},
		create_project() {
			this.projectDialog = true;
		},
		create_invoice() {
			this.CreateInvoiceDialog = true;
		},
		taskActivity() {
			this.$store
				.dispatch(GET, {
					url: `customer-task-log/${this.customerId}`,
				})
				.then((data) => {
					this.logData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		refreshMeeting() {
			this.getMeeting();
		},

		editMeeting(id) {
			this.open_dialog.meeting = true;
			this.$store
				.dispatch(GET, { url: `single-meeting/${id}` })
				.then((data) => {
					this.editMeetingData = data.meeting;
					this.attendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getMeeting() {
			this.$store
				.dispatch(QUERY, {
					url: `meeting-customer`,
					data: { related_to: "customer", customerId: this.customerId },
				})
				.then((data) => {
					this.meetingData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		open_meeting_drawer(name) {
			this.editMeetingData = {};
			this.attendieList = {};
			this.open_dialog.meeting = true;
			this.meeting_title = name;
		},
		routeToUpdate(id) {
			this.$router.push({
				name: "update-customer",
				params: { id },
				query: { t: new Date().getTime() },
			});
		},
		autoRefress() {
			this.autoUpdate = true;
		},
		doAction(row, param) {
			switch (param) {
				case "download":
					window.open(row.url, "_blank");
					break;
				case "delete":
					this.id = row.id;
					this.deleteDialog = true;
					break;
			}
		},
		getFileExtension(path) {
			if (path) {
				return last(path.split("."));
			}
		},
		uploadDialog() {
			this.openUploadModel = true;
		},
		deleteFile() {
			if (!this.id) {
				return false;
			}
			this.deleteLoading = true;
			ApiService.delete(`customer/${this.customerId}/attachment/customer_kpi/${this.id}`)
				.then(() => {
					this.deleteDialog = false;
					this.getKpi();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.deleteLoading = false;
				});
		},
		getKpi() {
			this.$store
				.dispatch(GET, {
					url: `customer/${this.customerId}/attachment/customer_kpi`,
				})
				.then((data) => {
					this.dbFiles = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getFileSize(size) {
			return round(toNumber(size) / 1024, 2);
		},
		openContactDrawer(id) {
			this.contactData = {};
			this.openContact = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `single-contact/${this.customerId}/${id}`,
				})
				.then((data) => {
					this.contactData = data;
					this.getSingleCustomer();
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getSingleCustomer() {
			this.$store
				.dispatch(GET, {
					url: `single-customer/${this.customerId}`,
				})
				.then((data) => {
					this.customerDetail = data.customer;
					this.salesPersonList = data.attendie[0];
					this.contactDetails = data.contact_person;
					this.servicesDetails = data.customer_services;
					this.attachmentDetails = data.attachment;
					this.customer_hosting = data.customer_hosting;
					this.customer_web = data.customer_web;
					this.customer_ssl = data.customer_ssl;
					this.domain = data.domain;
					this.maintenance = data.maintenance;
					this.customer_contract = data.customer_contract;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		/* getCustomerLists() {
			this.$store
				.dispatch(QUERY, {
					url: `customer`,
				})
				.then((data) => {
					this.customerList = data.tbody;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		}, */
		statusFilter(value = this.currentStatus.value, text = this.currentStatus.text) {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, { url: `customer`, data: { status: value, page: this.page } })
				.then((data) => {
					this.listingStatus = data.status;
					this.currentStatus = { text, value };
					if (this.page == 1) {
						this.statusData = [...data.tbody];
					} else {
						this.statusData = [...this.statusData, ...data.tbody];
					}
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		/* getCustomerSetting() {
			this.$store
				.dispatch(GET, {
					url: `customer-setting`,
				})
				.then((data) => {
					this.listingStatus = data.status;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		}, */
		tabDetails(id) {
			this.$router.push({
				params: { id },
				query: { t: new Date().getTime() },
			});
			this.customerId = id;
			this.refreshReminder();
			this.getSingleCustomer();
			this.taskActivity();
			this.getMeeting();
		},
		selectFile(index) {
			this.fileIndex = toSafeInteger(index);
			this.$refs["uploadFile"].$refs["input"].click();
		},
		addMoreFile() {
			this.fileList.push({
				icon: null,
				name: null,
				extension: null,
				size: null,
			});
		},
		closeDialog() {
			this.fileList = [];
			this.open_upload_dialog = false;
		},
		remove_file(index) {
			this.fileList.splice(index, 1);
		},
		updateContact() {
			this.getSingleCustomer();
			this.statusFilter();
			this.openContact = false;
		},
		getNotesRemark(filter) {
			this.$store
				.dispatch(QUERY, {
					url: `${this.getUrl}/${this.relatedType}/${this.relatedId}`,
					data: {
						search: this.DataSearch,
						filter: filter,
					},
				})
				.then((data) => {
					this.notesMsg = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
	},
	computed: {
		dataTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.dataTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "customer";
			},
		},

		showLoadMoreBtn: {
			get() {
				const allCustomers = parseInt(
					objectPath.get(
						this.listingStatus,
						`${this.currentStatus.value == "all" ? 0 : this.currentStatus.value}.status_count`
					)
				);
				if (allCustomers && allCustomers > 15 && allCustomers > this.statusData.length) {
					return true;
				} else {
					return false;
				}
			},
		},
	},
	mounted() {
		if (!this.getPermission("customer:view") && this.customerId > 0) {
			this.$router.replace({
				name: "customer",
				query: { t: new Date().getTime() },
			});
		}
		this.statusFilter();
		// this.getNotesRemark();
		this.getSingleCustomer();
		/* this.getCustomerLists();  // Krishn: Unnecessary ( not used anywhere same api call in [[ this.statusFilter();]] ) */
		/* this.getCustomerSetting(); */
		this.getKpi();
		this.taskActivity();
		this.getMeeting();
		this.getReminder(1);

		ContractEventBus.$on("parent-refresh", () => {
			this.statusFilter();
		});

		this.$store.dispatch(SET_BREADCRUMB, [
			/* { text: "Dashboard", disabled: false, href: "/dashboard" }, */
			{ text: "Customer", disabled: false, href: "/contacts/customer" },
			{ text: "Detail", disabled: true },
		]);
	},
	beforeMount() {
		this.customerId = toSafeInteger(this.$route.params.id);
	},
};
</script>
<style scoped>
.call-btn-underlay {
	position: absolute !important;
	z-index: -1 !important;
	top: 50% !important;
	left: 50% !important;
	-ms-transform: translate(-50%, -50%) !important;
	transform: translate(-50%, -50%) !important;
	margin: auto !important;
	animation: cp-widget-button-underlay 1.5s infinite;
	border-radius: 100% !important;
}

@keyframes cp-widget-button-underlay {
	0% {
		opacity: 0.25;
		width: 0;
		height: 0;
	}

	40% {
		opacity: 0.25;
	}

	100% {
		opacity: 0;
		width: 120px;
		height: 120px;
	}
}
</style>
